import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { AuthProvider } from 'contexts/AuthContext';
import { RouteUnauthenticated, RouteAuthenticated, fallbackUri } from 'utils/routeUtils';

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeEditorProvider>
				<AuthProvider>
					<HashRouter>
						<Switch>
							<RouteUnauthenticated path={`/auth`} component={AuthLayout} />
							<RouteAuthenticated path={`/admin`} component={AdminLayout} />
							<Redirect to={fallbackUri} />
						</Switch>
					</HashRouter>
				</AuthProvider>
			</ThemeEditorProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
