import { useState } from 'react';

export const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const reset = () => {
    setCurrentPage(1);
    setTotalPages(0);
  }

  return {
    currentPage,
    totalPages,
    setCurrentPage,
    setTotalPages,
    reset,
  }
}