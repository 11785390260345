import Axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
// import {logApi, logger} from '@utils/logger';
import {parseResponseData, parseResponseError} from './parseResponse';

const API_TIMEOUT = 30000; //30s

export const changeRequestConfig = (requestConfig: AxiosRequestConfig) => {
  const {url, method, headers} = requestConfig;
  // logApi(`${(method ?? '-').toUpperCase()} ${url ?? '-'}`, {headers});
  return requestConfig;
};

export const onErrorRequest = (error: any) => {
  return Promise.reject(error);
};

export const changeResponse = (response: AxiosResponse) => {
  // console.log(JSON.stringify(response));
  return parseResponseData(response);
};

export const onErrorResponse = (error: any) => {
  const {data} = parseResponseError(error);
  return Promise.reject(data);
};

export const restfulApi = (
  {
    baseURL,
    headers = {},
    timeout = API_TIMEOUT,
  }: {baseURL: string; headers?: any; timeout?: number},
  middleWare = {changeRequestConfig, changeResponse, onErrorRequest, onErrorResponse},
) => {
  // logger.info('create axios instance', baseURL);
  const instance = Axios.create({
    baseURL,
    timeout,
    headers,
  });

  if (middleWare.changeRequestConfig) {
    instance.interceptors.request.use(middleWare.changeRequestConfig, async error => {
      // logApi('onErrorRequest', error);
      if (onErrorRequest) {
        return onErrorRequest(error);
      }
      return Promise.reject(error);
    });
  }

  if (middleWare.changeResponse) {
    instance.interceptors.response.use(middleWare.changeResponse, async error => {
      // logApi('onErrorResponse', error);
      if (middleWare.onErrorResponse) {
        return middleWare.onErrorResponse(error);
      }
      return Promise.reject(error);
    });
  }

  return instance;
};
