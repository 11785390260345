import React from "react";
import { CONSTANTS } from "../../../assets/constants";
import { orderApi } from "../../../services/api/orderApi";
import { usePagination } from '../../../hooks/usePagination';

const ACTION_TYPES = {
  SET_LAST_UPDATED: "setLastUpdated",
  SET_ACTIVE_ORDERS: "setActiveOrders",
  SET_COMPLETED_ORDERS: "setCompletedOrders",
  SET_SCHEDULED_ORDERS: "setScheduledOrders",

  START_LOAD_ORDER_DETAIL: "startLoadOrderDetail",
  STOP_LOAD_ORDER_DETAIL: "stopLoadOrderDetail",
  SET_ORDER_DETAIL: "setOrderDetail",

  START_PRINT_ORDER: "startPrintOrder",
  STOP_PRINT_ORDER: "stopPrintOrder",

  START_SEARCH_ORDERS: "startSearchOrders",
  STOP_SEARCH_ORDERS: "stopSearchOrders",
  SET_SEARCH_ORDERS: "setSearchOrders",
};

const initState = {
  lastUpdated: "--",
  active: {
    totalItems: 0,
    items: [],
  },
  completed: {
    totalItems: 0,
    items: [],
  },
  scheduled: {
    totalItems: 0,
    items: [],
  },
  search: {
    loading: false,
    totalItems: 0,
    items: [],
  },
  orderDetail: {
    loading: false,
    data: null,
  },
  printOrder: {
    loading: false,
  }
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "setLastUpdated":
      return {
        ...state,
        lastUpdated: payload
      };
    case "setActiveOrders":
      return {
        ...state,
        active: payload
      };
    case "setCompletedOrders":
      return {
        ...state,
        completed: payload
      };
    case "setScheduledOrders":
      return {
        ...state,
        scheduled: payload
      };
    case ACTION_TYPES.START_SEARCH_ORDERS:
      return {
        ...state,
        search: {
          ...state.search,
          loading: true
        }
      }
    case ACTION_TYPES.STOP_SEARCH_ORDERS:
      return {
        ...state,
        search: {
          ...state.search,
          loading: false
        }
      }
    case ACTION_TYPES.SET_SEARCH_ORDERS:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload
        }
      }
    case ACTION_TYPES.START_LOAD_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: {
          ...state.orderDetail,
          loading: true
        }
      }
    case ACTION_TYPES.STOP_LOAD_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: {
          ...state.orderDetail,
          loading: false
        }
      }
    case ACTION_TYPES.SET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: {
          ...state.orderDetail,
          data: payload
        }
      }
    case ACTION_TYPES.START_PRINT_ORDER:
      return {
        ...state,
        printOrder: {
          loading: true
        }
      }
    case ACTION_TYPES.STOP_PRINT_ORDER:
      return {
        ...state,
        printOrder: {
          loading: false
        }
      }
    default:
      return state;
  }
};
export const useOrders = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [state, dispatch] = React.useReducer(reducer, initState);
  const [isSearchMode, setSearchMode] = React.useState(false);
  const pagination = usePagination();
  const keywordRef = React.useRef('');

  const reloadData = React.useCallback((tabIndex) => {
    let status = '';
    let actionType = '';
    switch (tabIndex) {
      case 0:
        status = 'incompleted';
        actionType = 'setActiveOrders';
        break;
      case 1:
        status = 'completed';
        actionType = 'setCompletedOrders';
        break;
      // case 2:
      //   status = 'scheduled';
      //   actionType = 'setScheduledOrders';
      //   break;
      default:
        throw new Error(`Invalid tab index: ${tabIndex}`);
    }

    dispatch({ type: "setLastUpdated", payload: new Date().toString() });
    orderApi
      .getOrders(status, pagination.currentPage) //format
      .then((response) => {
        dispatch({type: actionType, payload: response});
        pagination.setTotalPages(response.totalPages);
      })
      .catch(error => {
        console.log(error);
      });
  }, [pagination.currentPage]);

  React.useEffect(() => {
    if (isSearchMode) {
      return;
    }
    reloadData(tabIndex);
    const intervalId = setInterval(() => {
      reloadData(tabIndex);
    }, CONSTANTS.ORDERS_RELOAD_INTERVAL * 1000);
    return () => clearInterval(intervalId);
  }, [reloadData, tabIndex, isSearchMode]);

  const onClickSearch = async (keyword, pageNo = 1) => {
    if (!keyword.trim()) {
      return;
    }
    keywordRef.current = keyword;
    setSearchMode(true);
    if (pageNo === 1) {
      pagination.reset();
    }

    dispatch({type: ACTION_TYPES.START_SEARCH_ORDERS});
    try {
      const response = await orderApi.searchOrders(keyword, pageNo);
      pagination.setTotalPages(response.totalPages);
      dispatch({type: ACTION_TYPES.SET_SEARCH_ORDERS, payload: response});
    } catch (error) {
      console.log(error);
    }
    dispatch({type: ACTION_TYPES.STOP_SEARCH_ORDERS});
  };

  const onClickClearSearch = () => {
    setSearchMode(false);
    dispatch({ type: ACTION_TYPES.SET_SEARCH_ORDERS, payload: initState.search });
  };

  const onChangeTabIndex = (index) => {
    pagination.reset();
    setTabIndex(index);
  };

  const onChangePagination = (nextPage) => {
    if (isSearchMode) {
      onClickSearch(keywordRef.current, nextPage);  
    } else {
      pagination.setCurrentPage(nextPage);
    }
  };

  const onClickOrderDetail = async (orderId) => {
    dispatch({type: ACTION_TYPES.START_LOAD_ORDER_DETAIL});
    try {
      const response = await orderApi.getOrderDetail(orderId);
      dispatch({type: ACTION_TYPES.SET_ORDER_DETAIL, payload: response});
    } catch (error) {
      console.log(error);
    }
    dispatch({type: ACTION_TYPES.STOP_LOAD_ORDER_DETAIL});
  };  

  const onClickPrint = async () => {
    dispatch({type: ACTION_TYPES.START_PRINT_ORDER});
    try {
      await orderApi.printOrder(state.orderDetail.data.id); 
    } catch (error) {
      console.log(error); 
    }
    dispatch({type: ACTION_TYPES.STOP_PRINT_ORDER});
  };

  return {
    isSearchMode,
    tabIndex, onChangeTabIndex,
    lastUpdated: state.lastUpdated,
    orderData: [
      {
        label: `In Progress (${state.active.totalItems})`,
        content: state.active.items,
      },
      {
        label: `Completed (${state.completed.totalItems})`,
        content: state.completed.items,
      },
      // {
      //   label: `Scheduled (${state.scheduled.totalItems})`,
      //   content: state.scheduled.items,
      // },
    ],
    pagination,
    onChangePagination,

    onClickSearch,
    onClickClearSearch,
    loadingSearch: state.search.loading,
    searchData: state.search.items,

    orderDetail: state.orderDetail,
    onClickOrderDetail,
    loadingPrint: state.printOrder.loading,
    onClickPrint,
  };
};
