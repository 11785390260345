import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { handleUnauthenticated } from '../services/api/utils/apiInstance';
import { authApi } from '../services/api/authApi';

export const useLogout = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const cancelRef = React.useRef();

  const onLogout = async () => {
    const {accessToken, refreshToken} = JSON.parse(localStorage.getItem('user') || '{}');
    try {
      await authApi.logout(accessToken, refreshToken)
    } catch (error) {
      // skip error
    }
    handleUnauthenticated();
  }

	const onShowConfirm = () => {
    onOpen();
	}

  return {
    isOpen,
    cancelRef,
    onClose,
    onShowConfirm,
    onLogout,
  };
}