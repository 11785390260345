import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "services/storage/authStorage";

export const RouteAuthenticated = ({ component: Component, path }) => {
	if (!isAuthenticated()) {
		return <Redirect to="/auth" />;
	}
	return <Route component={Component} path={path} />;
};

export const RouteUnauthenticated = ({ component: Component, path }) => {
	if (isAuthenticated()) {
		return <Redirect to="/admin" />;
	}
	return <Route component={Component} path={path} />;
};

export const fallbackUri = `${isAuthenticated() ? '/admin' : '/auth'}`;

