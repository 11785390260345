import React from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Tag,
  Text
} from "@chakra-ui/react";

import { MdComment, MdKeyboardArrowRight, MdReceiptLong } from "react-icons/md";

import AvatarBamiApp from "assets/img/orders/avatar-bami-app.png";
import { timeUtils } from 'utils/timeUtils';
import { stringUtils } from 'utils/stringUtils';


export const ListItem = ({data, orderDetail, loading, onClickDetail, renderDetail}) => {
  const {invoiceNo, orderStatus, preferredTime, note, deliveryMethodName} = data;

  // const customerName = React.useMemo(() => {
  //   if (orderDetail?.data?.id === data?.id) {
  //     return orderDetail?.data.customerName; 
  //   } 
  // }, [data?.id, orderDetail?.data]);

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Avatar size="sm" src={AvatarBamiApp} />
          <Flex
            textAlign="left"
            marginLeft={4}
            flexDirection={{base: "column", md: "row"}}
          >
            <Text fontWeight="bold" mr={2}>
              {invoiceNo}
            </Text>
            <Tag
              borderRadius="full"
              variant="solid"
              backgroundColor={getStatusColor(orderStatus)}
            >
              {stringUtils.capitalizeFirstLetter(orderStatus)}
            </Tag>
          </Flex>
          <Spacer />
          <Text fontWeight="bold" color="green">
            {timeUtils.formatPreferredTime(preferredTime)}
          </Text>
          {/* <AccordionIcon /> */}
        </AccordionButton>
      </h2>
      {/* <AccordionPanel pb={4}>
        <Box textAlign="right">
          <Button
            size="sm"
            leftIcon={<MdReceiptLong />}
            variant="outline"
            isLoading={loading}
            onClick={onClickDetail}
          >
            Order Detail
          </Button>
        </Box>
        <Divider marginTop={2} marginBottom={2} />
        
        <Flex alignItems="center">
          <MdComment />
          <Text ml={2}>{note}</Text>
          <Tag ml={2} borderRadius='full' variant='solid'>{deliveryMethodName}</Tag>
        </Flex> 

        {renderDetail()}
      </AccordionPanel> */}
    </AccordionItem>
  );
}

const getStatusColor = status => {
  switch (status) {
    case 'active':
      return '#4CAF50';
    case 'scheduled':
      return '#FFC107';
    default:
      return '#2196F3';
  }
};
