import {AxiosError, AxiosResponse} from 'axios';

export function parseResponseData(results: AxiosResponse) {
  const responseStatus = results?.status;
  if (responseStatus && responseStatus >= 200 && responseStatus < 400) {
    return results.data.data;
  }

  const message = results?.data?.message || 'NTW_UNKNOWN_ERROR';
  throw new Error(message);
}

export function parseResponseError(axiosError: AxiosError) {
  const data = axiosError.response?.data;
  if (data && data.error) {
    return {
      isSuccess: false,
      data: {
        message: data.message,
        code: data.error?.code,
        traceId: data.error?.traceId,
        payload: data.error,
        statusCode: axiosError.response?.status,
      },
    };
  }
  if (!axiosError.response) {
    return {
      isSuccess: false,
      data: {
        message: 'Please check your internet connection and try again.',
      },
    };
  }
  return {
    isSuccess: false,
    data: {
      ...axiosError,
      message: null,
      code: axiosError?.response?.status,
    },
  };

}

