import React from "react";
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
export function SearchBar({loading, isShowClearButton, onClickSearch, onClickClearSearch}) {
  const searchIconColor = useColorModeValue("gray.700", "white");
  const [value, setValue] = React.useState("");
  const onChange = (event) => {
    setValue(event.target.value);
  };
  const onSearch = () => {
    onClickSearch(value);
  };
  const onClearSearch = () => {
    setValue("");
    onClickClearSearch();
  };
  return (
    <Flex>
      <InputGroup w={{base: "100%", md: "200px"}}>
        <InputLeftElement
          children={
            <IconButton
              bg="inherit"
              borderRadius="inherit"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
            />
          }
        />
        <Input
          placeholder={"Search orders"}
          value={value}
          onChange={onChange}
          onKeyUp={(e) => e.key === "Enter" && onSearch()}
        />
      </InputGroup>
      <Button variant="outline" ml={2} isLoading={loading} onClick={onSearch}>
        Search
      </Button>
      {isShowClearButton && (
        <Button variant="outline" ml={2} onClick={onClearSearch}>
          Clear
        </Button>
      )}
    </Flex>
  );
}
