
export const isAuthenticated = () => {
	const loggedInUser = localStorage.getItem("user");
	if (loggedInUser) {
		return true;
	}
	return false;
};

export const getUserInfo = () => {
	const {username} = JSON.parse(localStorage.getItem('user_info') || '{}');
	return {username};
}

export const setUserInfo = ({username}) => {
	localStorage.setItem('user_info', JSON.stringify({username}));
}
