import React from "react";
import { useHistory } from "react-router-dom";
import { authApi } from "../services/api/authApi";
import { handleAuthenticated } from '../services/api/utils/apiInstance';
import { setUserInfo } from '../services/storage/authStorage';

export const useLogin = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const login = async (username, password) => {
    setLoading(true);
    try {
      const response = await authApi.login(username, password);
      setUserInfo({username});
      handleAuthenticated(response);
      history.push('/admin');
      window.location.reload();
    } catch (error) {
      alert('Login failed', error);
    }
    setLoading(false);
  };
  return {
    loading,
    login
  };
};
