import { apiInstance } from './utils/apiInstance';
import { formatPrice } from '../../utils/formatPrice';
import { timeUtils } from '../../utils/timeUtils';

let deliveryMethods = null;
const getDeliveryMethods = async () => {
  return apiInstance.get('v1/pos/delivery-methods').then(response => response.deliveryMethods)
}

const parseOrders = response => ({
  totalItems: response.pagination.totalItems,
  totalPages: response.pagination.totalPages,
  items: response.orders.map(order => ({
    id: order.id,
    invoiceNo: order.invoiceNo,
    orderStatus: order.receivingStatus,
    customerName: order.customerName,
    orderTime: order.updatedDate,
    preferredTime: order.deliveryMethod.preferredTime,

    note: order.note || '--',
    deliveryMethodName: deliveryMethods?.find(method => method.id === order.deliveryMethod.id)?.name || '--',

    price: formatPrice(order.total)
  }))
})

const getOrders = async (receivingStatus, pageNo = 1, pageItems = 10) => {
  if (!deliveryMethods) {
    deliveryMethods = await getDeliveryMethods();
  }
  return apiInstance
    .get(`v1/pos/orders/${receivingStatus}?itemsPerPage=${pageItems}&pageNo=${pageNo}`)
    .then(parseOrders);
}

const searchOrders = async (keyword, pageNo = 1, pageItems = 10) => {
  return apiInstance
    .get(`v1/pos/orders?q=${keyword}&itemsPerPage=${pageItems}&pageNo=${pageNo}`)
    .then(parseOrders);
}

const getOrderDetail = async (orderId) => {
  return apiInstance.get(`v1/pos/orders/${orderId}`).then(order => ({
    id: order.id,
    invoiceNo: order.invoiceNo,
    numberOfItems: order.totalLineItems,
    total: formatPrice(order.total),
    subtotal: formatPrice(order.subtotal),
    orderDiscount: formatPrice(-order.orderDiscount),
    codes: (order.codes || []).join(', '),

    orderTime: timeUtils.formatPreferredTime(order.updatedDate),
    preferredTime: timeUtils.formatPreferredTime(order.deliveryMethod.preferredTime),
    
    note: order.note || '--',
    deliveryMethodName: deliveryMethods?.find(method => method.id === order.deliveryMethod.id)?.name || '--',

    customerName: order.customerProfile?.fullName || '--',
    customerAddress: order.customerProfile?.location?.fullAddress || '--',
    customerPhoneNumber: order.customer?.phoneNumber,

    items: order.lineItems.map(item => ({
      id: item.id,
      name: item.productName,
      description: item.description,
      quantity: item.quantity,
    }))
  }))
}

const printOrder = async (orderId) => {
  return apiInstance.post(`v1/pos/orders/${orderId}/print`)
}

export const orderApi = {
  getOrders,
  searchOrders,
  getOrderDetail,
  getDeliveryMethods,
  printOrder,
} 
