import { AxiosError } from 'axios';
import {
  changeResponse,
  onErrorRequest,
  onErrorResponse,
  restfulApi
} from "./restfulApi";

import { CONSTANTS } from '../../../assets/constants';
import { authApi } from '../authApi';
import { getApiHeaders } from './getApiHeaders';

export const handleAuthenticated = async (data) => {
  localStorage.setItem('user', JSON.stringify(data));
  // apiInstance.defaults.headers.set('Authorization', `Bearer ${data.accessToken}`);
  // apiInstance.defaults.headers['Authorization'] = `Bearer ${data.accessToken}`
};

export const handleUnauthenticated = async () => {
  localStorage.removeItem('user');
  window.location.reload();
};

const isGetTokenUrl = (url) => {
  return url && url.includes('auth/refresh');
};

const handleUnAuthorizedRequest = async () => {
  // logger.info('handleUnAuthorizedRequest');
  handleUnauthenticated();
};

const refreshAccessToken = async () => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  const res = await authApi.refreshToken(user.accessToken, user.refreshToken);
  await handleAuthenticated(res);
  return res.accessToken;
};

const onErrorResponse401 = async (error: AxiosError) => {
  const api = apiInstance;
  const originalRequest = error.config;
  const needRefreshToken = error?.response?.status === 401;

  if (needRefreshToken && isGetTokenUrl(originalRequest.url)) {
    await handleUnAuthorizedRequest();
    return;
  }

  if (needRefreshToken && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      const newAccessToken = await refreshAccessToken();

      // Cập nhật header và gửi lại yêu cầu ban đầu với token mới
      originalRequest.headers.set('Authorization', `Bearer ${newAccessToken}`);
      // api.defaults.headers.set('Authorization', `Bearer ${newAccessToken}`);

      // Thực hiện lại yêu cầu ban đầu
      return api(originalRequest);
    } catch (refreshError) {
      // logger.error(refreshError);
      // Xử lý khi làm mới token không thành công
      await handleUnAuthorizedRequest();
    }
  }

  return onErrorResponse(error);
};

export const apiInstance = restfulApi(
  {
    baseURL: CONSTANTS.API_URL,
    headers: {
      ...getApiHeaders(),
    },
  },
  {
    changeRequestConfig: (requestConfig) => {
      const accessToken = JSON.parse(localStorage.getItem('user') || '{}').accessToken;
      if (accessToken) {
        requestConfig.headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return requestConfig;
    },
    changeResponse,
    onErrorRequest,
    onErrorResponse: onErrorResponse401,
  },
);