import {
  Accordion,
  Box,
  CircularProgress,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import React from "react";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/minimal.css';


import { ListItem } from "./components/ListItem";
import { OrderDetailView } from './components/OrderDetailView';
import { useOrders } from './useOrders';
import { SearchBar } from './components/SearchBar';
import { CONSTANTS } from '../../../assets/constants';
import { MdInfo } from 'react-icons/md';

const MAX_VALUE = CONSTANTS.ORDERS_RELOAD_INTERVAL;
const LastUpdatedView = ({lastUpdatedTime}) => {
  const [value, setValue] = React.useState(MAX_VALUE);

  React.useEffect(() => {
    setValue(MAX_VALUE);
  }, [lastUpdatedTime]);
  
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setValue((prevValue) => prevValue - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [setValue, value, lastUpdatedTime]);

  return (
    <Flex direction={"row"} alignItems={'center'} justifyContent={'flex-end'} mb={2}>
      <Text fontSize={"sm"} color={'gray.400'}>{`Last updated ${MAX_VALUE - value} seconds ago`}</Text>
      <CircularProgress
        ml={2}
        size={"5"}
        max={MAX_VALUE}
        min={0}
        value={value}
        color="#E0E5F2"
        trackColor="#3965FF"
      />
    </Flex>
  );
}

export default function Orders() {
  const [isOpen, setIsOpen] = React.useState(false);
  const finalRef = React.useRef(null);
  const orderModule = useOrders();

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const renderList = (orders) => {
    if (orders.length === 0) {
      return (
        <Box mt={4} mb={4} alignItems={"center"} justifyContent={"center"} display={"flex"}>
          <MdInfo />
          <Text ml={2}>No orders</Text>
        </Box>
      )
    }
    return (
      <Accordion
        allowToggle
        background="white"
        onChange={async (value) => {
          setIsOpen(true);
          if (value !== -1) {
            const orderId = orders[value].id;
            await orderModule.onClickOrderDetail(orderId);
          }
        }}
      >
        {orders.map((item, itemIndex) => (
          <ListItem
            key={itemIndex}
            data={item}
            orderDetail={orderModule.orderDetail}
            // loading={orderModule.orderDetail.loading}
            // onClickDetail={() => {
            //   setIsOpen(true);
            // }}
            // renderDetail={() => {
            //   if (!orderModule.orderDetail?.data || orderModule.orderDetail.loading) {
            //     return <Text>Loading...</Text>;
            //   }
            //   const {
            //     items,
            //     numberOfItems,
            //     totalPrice
            //   } = orderModule.orderDetail?.data;
            //   return (
            //     <>
            //       <Divider mt={2} mb={2} />
            //       <Flex>
            //         <Text fontWeight={"bold"}>{`${numberOfItems} Item(s)`}</Text>
            //         <Spacer />
            //         <Text fontWeight={"bold"}>{totalPrice}</Text>
            //       </Flex>
            //       <Divider marginTop={2} marginBottom={2} />
            //       {items.map((item, index) => (
            //         <Flex key={index} alignItems="center" mb={2}>
            //           <Flex>
            //             <Text>{item.quantity}</Text>
            //             <Box ml={2}>
            //               <Text>{item.name}</Text>
            //               {item.description.split("\n").map((line, index) => (
            //                 <Text key={index}>{line}</Text>    
            //               ))}
            //             </Box>
            //           </Flex>
            //         </Flex>
            //       ))}
            //     </>
            //   );
            // }}
          />
        ))}
      </Accordion>
    );
  }

  const renderTabs = () => {
    return (
      <>
        <LastUpdatedView lastUpdatedTime={orderModule.lastUpdated} />
        <Tabs
          variant="soft-rounded"
          defaultIndex={orderModule.tabIndex}
          onChange={orderModule.onChangeTabIndex}
          isLazy={true}
          lazyBehavior='unmount'
        >
          <TabList>
            {orderModule.orderData.map((tab, index) => (
              <Tab key={index}>{tab.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {orderModule.orderData.map((tab, tabIndex) => (
              <TabPanel p={4} key={tabIndex}>
                {renderList(tab.content)}
              </TabPanel>
            ))}
            <TabPanel></TabPanel>
          </TabPanels>
        </Tabs>
      </>
    );
  }

  const renderSearchResult = () => {
    return renderList(orderModule.searchData)
  }

  const renderPagination = () => {
    if (orderModule.pagination.totalPages <= 1) {
      return null;
    }
    return (
      <Box mt={4} mb={4}>
        <ResponsivePagination
          current={orderModule.pagination.currentPage}
          total={orderModule.pagination.totalPages}
          onPageChange={orderModule.onChangePagination}
        />
      </Box>
    );
  };

  const renderModals = () => {
    return (
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manager order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {orderModule.orderDetail.data && (
              <OrderDetailView
                loading={orderModule.orderDetail.loading}
                data={orderModule.orderDetail.data}
                loadingPrint={orderModule.loadingPrint}
                onClickPrint={orderModule.onClickPrint}
              />
            )}
          </ModalBody>
          {/* <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={orderModule.onClickPrint}
            >
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Box pt={{base: "50px", md: "60px", xl: "60px"}}>
      <SearchBar
        loading={orderModule.loadingSearch}
        isShowClearButton={orderModule.isSearchMode}
        onClickSearch={orderModule.onClickSearch}
        onClickClearSearch={orderModule.onClickClearSearch}
      />
      <Divider mt={2} mb={2} />
      {orderModule.isSearchMode ? renderSearchResult() : renderTabs()}
      {renderPagination()}
      {renderModals()}
    </Box>
  );
}
