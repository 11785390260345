import { apiInstance } from './utils/apiInstance';

const login = async (username, password) => {
  const data = {username, password};
  return apiInstance.post('v1/pos/auth/login', data)
}

const refreshToken = async (accessToken, refreshToken) => {
  return apiInstance.post('v1/pos/auth/refresh', {accessToken, refreshToken})
}

const logout = async (accessToken, refreshToken) => {
  const data = {accessToken, refreshToken};
  return apiInstance.post('v1/pos/auth/logout', data)
}

export const authApi = {
  login,
  refreshToken,
  logout,
};
